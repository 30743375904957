<template>
  <Container :title="$t('personalInfoPage.addNewCard')">
    <div class="inside-container">
      <v-form ref="addNewCardForm" v-model="formValid" :disabled="!VUE_APP_STRIPE_PUBLISHABLE_KEY">
        <v-row>
          <v-col>
            <p v-text="!currentAccount.company
              ? $t('stripe.addNewCardHintForPrivateAccount')
              : $t('stripe.addNewCardHintForCompany')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <i18n path="stripe.privacyNotice">
                <template #link>
                  <a :href="$t('stripe.privacyNoticeUrl')" v-text="$t('stripe.privacyNoticeUrlText')"/>
                </template>
              </i18n>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <label class="label" v-text="$t('stripe.cardNumber')"/>
            <div :class="cardNumberRequired ? 'field-error' : ''">
              <div id="card-number" ref="cardNumber" :class="theme"/>
            </div>
            <div v-show="cardNumberRequired" class="custom-error v-text-field__details">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message" v-text="$t('errors.fieldRequired')"/>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3" cols="12">
            <label class="label" v-text="$t('stripe.cardExpiry')"/>
            <div :class="cardExpiryRequired ? 'field-error' : ''">
              <div id="card-expiry" ref="cardExpiry" :class="theme"/>
            </div>
            <div v-show="cardExpiryRequired" class="custom-error v-text-field__details">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message" v-text="$t('errors.fieldRequired')"/>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3" cols="12">
            <label class="label" v-text="$t('stripe.cardCVC')"/>
            <div :class="cardCvcRequired ? 'field-error' : ''">
              <div id="card-cvc" ref="cardCvc" :class="theme"/>
            </div>
            <div v-show="cardCvcRequired" class="custom-error v-text-field__details">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message" v-text="$t('errors.fieldRequired')"/>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12">
            <label class="label" v-text="$t('stripe.cardHolder')"/>
            <v-text-field
              id="stripe_card_holder"
              v-model="cardHolder"
              filled
              :rules="[() => !!cardHolder || $t('errors.fieldRequired'),]"
              :placeholder="$t('stripe.cardHolderPlaceholder')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <label class="label" v-text="$t('stripe.cardCountry')"/>
            <v-select
              id="stripe_card_country"
              v-model="cardCountry"
              filled
              :items="countries.country"
              item-value="code"
              :rules="[(v) => !!v || $t('errors.fieldRequired')]"
              :loading="loadingCountries"
              required
              :placeholder="$t('stripe.cardCountryPlaceholder')"
            >
              <template #selection="{ item }">
                <span v-text="$t(`countries.${item}`)"/>
              </template>
              <template #item="{ item }">
                <BaseCountry :country="item" no-tooltip/>
                <span class="pl-4" v-text="$t(`countries.${item}`)"/>
              </template>
            </v-select>
          </v-col>
          <v-col md="6" cols="12">
            <label class="label" v-text="$t('stripe.cardCity')"/>
            <v-text-field
              id="stripe_card_city"
              v-model="cardCity"
              filled
              :rules="[() => !!cardCity || $t('errors.fieldRequired'),]"
              :placeholder="$t('stripe.cardCityPlaceholder')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="label" v-text="$t('stripe.cardState')"/>
            <v-text-field
              id="stripe_card_state"
              v-model="cardState"
              filled
              :rules="[() => !!cardState || $t('errors.fieldRequired'),]"
              :placeholder="$t('stripe.cardStatePlaceholder')"
            />
          </v-col>
          <v-col>
            <label class="label" v-text="$t('stripe.cardPostalCode')"/>
            <v-text-field
              id="stripe_card_postal_code"
              v-model="cardPostalCode"
              filled
              :rules="[() => !!cardPostalCode || $t('errors.fieldRequired'),]"
              :placeholder="$t('stripe.cardPostalCodePlaceholder')"
            />
          </v-col>
          <v-col>
            <label class="label" v-text="$t('stripe.cardLine1')"/>
            <v-text-field
              id="stripe_card_line_1"
              v-model="cardLine1"
              filled
              :rules="[() => !!cardLine1 || $t('errors.fieldRequired'),]"
              :placeholder="$t('stripe.cardLine1Placeholder')"
            />
          </v-col>
          <v-col>
            <label class="label" v-text="$t('stripe.cardLine2')"/>
            <v-text-field
              id="stripe_card_line_2"
              v-model="cardLine2"
              filled
              :rules="[() => !!cardLine2 || $t('errors.fieldRequired'),]"
              :placeholder="$t('stripe.cardLine2Placeholder')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              tile
              large
              elevation="0"
              :loading="loading"
              color="primary"
              :disabled="!VUE_APP_STRIPE_PUBLISHABLE_KEY"
              @click="addNewCard"
              v-text="$t('stripe.add')"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </Container>
</template>

<script>

import Vue from 'vue'
import {VUE_APP_STRIPE_PUBLISHABLE_KEY} from '../../../config'

export default Vue.extend({
  name: "AddNewCard",
  props: {
    companyPage: { type: Boolean, default: false },
  },
  data() {
    return {
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardHolder: '',
      cardCountry: '',
      cardCity: '',
      cardLine1: '',
      cardLine2: '',
      cardState: '',
      cardPostalCode: '',
      loading: false,
      formValid: false,
      cardNumberRequired: false,
      cardExpiryRequired: false,
      cardCvcRequired: false,
      loadingCountries: true,
      countries: {},
    }
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements({
        locale: this.$i18n.locale,
      })
    },
    currentAccount: function() {
      return this.$store.state.account
    },
    theme() {
      return this.$vuetify.theme.dark ? 'theme--dark' : ''
    },
  },
  updated() {
    this.stripeInit()
  },
  beforeDestroy() {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  mounted() {
    this.fetchCountries()
  },
  methods: {
    clearForm() {
      this.cardNumber.clear()
      this.cardExpiry.clear()
      this.cardCvc.clear()
      this.cardHolder = ''
      this.$refs.addNewCardForm.reset()
      this.$refs.addNewCardForm.resetValidation()
      this.clearRequiredIframe()
    },
    fetchCountries() {
      this.loadingCountries = true
      this.axios
        .get(`/auth/registration_hints/`)
        .then((response) => {
          this.loadingCountries = false
          this.countries = response.data
        })
        .catch(() => {
          /* TODO so what do we do if this fails? */
          this.loadingCountries = false
        })
    },
    clearRequiredIframe() {
      this.cardNumberRequired = false
      this.cardExpiryRequired = false
      this.cardCvcRequired = false
    },
    async addNewCard() {
      this.loading = true
      this.clearRequiredIframe()

      const cardNumberEmpty = this.$refs.cardNumber.classList.contains('StripeElement--empty')
      const cardExpiryEmpty = this.$refs.cardExpiry.classList.contains('StripeElement--empty')
      const cardCvcEmpty = this.$refs.cardCvc.classList.contains('StripeElement--empty')

      if (cardNumberEmpty) {
        this.cardNumberRequired = true
      }

      if (cardExpiryEmpty) {
        this.cardExpiryRequired = true
      }

      if (cardCvcEmpty) {
        this.cardCvcRequired = true
      }
      this.$refs.addNewCardForm.validate()

      if (!this.formValid || cardNumberEmpty || cardExpiryEmpty || cardCvcEmpty) {
        this.loading = false
        return
      }

      const {paymentMethod, error} = await this.$stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
        billing_details: {
          name: this.cardHolder,
          address: {
            city: this.cardCity,
            country: this.cardCountry,
            line1: this.cardLine1,
            line2: this.cardLine2,
            postal_code: this.cardPostalCode,
            state: this.cardState,
          },
        },
      })

      if (error) {
        this.loading = false
        this.$store.commit('pushMessage', {
          icon: 'mdi-alert',
          text: error.message,
        })
        return null
      }

      this.$http
        .post(`/payments/cards/`, {
          "payment_method": paymentMethod.id,
          "investment_account_id": this.currentAccount.id,
        })
        .then(() => {
          this.$store.commit('pushMessage', {
            icon: 'mdi-robot-happy',
            text: this.$t('stripe.newCardAdded'),
          })
          this.loading = false
          this.clearForm()
          this.$auth.fetch()
          this.$router.push({ name: 'Personal', hash: '#addNewCard'})
        })
        .catch(() => {
          this.loading = false
        })
    },
    stripeInit() {
      const style = {
        base: {
          color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
          fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          fontWeight: 400,
          '::placeholder': {
            color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.38)' : 'rgba(0, 0, 0, 0.38)',
            fontWeight: 400,
            fontSize: '16px',
          },
        },
        invalid: {
          color: '#ff5252',
          iconColor: '#ff5252',
        },
      }

      this.cardNumber = this.stripeElements.create('cardNumber', { style })
      this.cardNumber.mount('#card-number')
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = this.stripeElements.create('cardCvc', { style })
      this.cardCvc.mount('#card-cvc')
    },
  },
})
</script>

<style scoped>
#card-cvc,
#card-expiry,
#card-number{
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background: rgba(0, 0, 0, 0.06);
  padding: 0 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  margin-bottom: 8px;
}

#card-cvc.theme--dark,
#card-expiry.theme--dark,
#card-number.theme--dark{
  background: rgba(255, 255, 255, 0.08);
}

.field-error #card-number,
.field-error #card-expiry,
.field-error #card-cvc{
  border-bottom: 1px solid #ff5252;
}

.custom-error{
  padding: 0 12px;
  margin-bottom: 8px;
}

#card-cvc:hover,
#card-expiry:hover,
#card-number:hover{
  background:rgba(0, 0, 0, 0.12)
}

#card-cvc.theme--dark:hover,
#card-expiry.theme--dark:hover,
#card-number.theme--dark:hover{
  background: rgba(255, 255, 255, 0.16);
}

.label{
  font-size: 12px;
}

</style>

